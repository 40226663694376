import { QuestionaryT, motivations } from 'types/questionary';
import baseDataLayer, { dataLayerEventParamsTemplate } from './baseDataLayer';

const baseAsesorDigitalData = {
  product: 'Asesor Digital',
};

export const trackViewLandingPage = (): void =>
  baseDataLayer({
    event_params: {
      ...dataLayerEventParamsTemplate,
      ...baseAsesorDigitalData,
      page: 'asesor-digital/landing',
      page_title: 'Asesor Digital - Landing',
      action_type: 'Pageview',
      action: 'Paso 0 – Asesor Digital - Landing',
    },
  });

export const trackViewProfilingPageEvent = (leadProfileData: QuestionaryT): void => {
  const motivation = motivations.find(
    (item): boolean => item.motivation === leadProfileData.motivation
  );
  baseDataLayer({
    event_params: {
      ...dataLayerEventParamsTemplate,
      ...baseAsesorDigitalData,
      page: 'asesor-digital/cuestionario',
      page_title: 'Asesor Digital - Perfilamiento',
      action_type: 'Pageview',
      action: 'Paso 1 – Asesor Digital - Perfilamiento',
      ahorro: leadProfileData.savings || '',
      edad: leadProfileData.age || '',
      sexo: leadProfileData.gender
        ? leadProfileData.gender === 'M'
          ? 'Masculino'
          : 'Femenino'
        : '',
      otro: motivation?.product,
      proteger_carga: leadProfileData.hasChildren
        ? leadProfileData.hasChildren
          ? 'si tiene hijos'
          : 'no tiene hijos'
        : '',
      valor: leadProfileData.savingsGoalAmount || '',
    },
  });
};

export const trackViewProductDetailPageEvent = (
  leadProfileData: QuestionaryT,
  productRecommeded: string
): void => {
  const motivation = motivations.find(
    (item): boolean => item.motivation === leadProfileData.motivation
  );
  baseDataLayer({
    event_params: {
      ...dataLayerEventParamsTemplate,
      ...baseAsesorDigitalData,
      page: 'asesor-digital/mi-seguro-con-ahorro',
      page_title: 'Asesor Digital - Conocer producto',
      action_type: 'Pageview',
      action: 'Paso 2 – Asesor digital – Conocer producto',
      ahorro: leadProfileData.savings,
      edad: leadProfileData.age,
      sexo: leadProfileData.gender === 'M' ? 'Masculino' : 'Femenino',
      otro: motivation?.product,
      proteger_carga: leadProfileData.hasChildren ? 'si tiene hijos' : 'no tiene hijos',
      valor: leadProfileData.savingsGoalAmount,
      product_type: productRecommeded,
    },
  });
};

export const trackViewFormPageEvent = (
  leadProfileData: QuestionaryT,
  productRecommeded: string
): void => {
  const motivation = motivations.find(
    (item): boolean => item.motivation === leadProfileData.motivation
  );
  baseDataLayer({
    event_params: {
      ...dataLayerEventParamsTemplate,
      ...baseAsesorDigitalData,
      page: 'asesor-digital/contacto',
      page_title: 'Asesor Digital - Formulario de contacto',
      action_type: 'Pageview',
      action: 'Paso 3 – Asesor Digital - Formulario de contacto',
      ahorro: leadProfileData.savings,
      edad: leadProfileData.age,
      sexo: leadProfileData.gender === 'M' ? 'Masculino' : 'Femenino',
      otro: motivation?.product,
      proteger_carga: leadProfileData.hasChildren ? 'si tiene hijos' : 'no tiene hijos',
      valor: leadProfileData.savingsGoalAmount,
      product_type: productRecommeded,
    },
  });
};

export const trackClickSuccessPageEvent = (
  leadProfileData: QuestionaryT,
  productRecommeded: string
): void => {
  const motivation = motivations.find(
    (item): boolean => item.motivation === leadProfileData.motivation
  );

  baseDataLayer({
    event_params: {
      ...dataLayerEventParamsTemplate,
      ...baseAsesorDigitalData,
      page: 'asesor-digital/exito',
      page_title: 'Asesor Digital - Exito Formulario de contacto',
      action_type: 'Pageview',
      action: 'Paso 4 – Asesor Digital - Exito Formulario de contacto',
      ahorro: leadProfileData.savings,
      edad: leadProfileData.age,
      sexo: leadProfileData.gender === 'M' ? 'Masculino' : 'Femenino',
      otro: motivation?.product,
      proteger_carga: leadProfileData.hasChildren ? 'si tiene hijos' : 'no tiene hijos',
      valor: leadProfileData.savingsGoalAmount,
      product_type: productRecommeded,
    },
  });
};
