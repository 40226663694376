import { ChangeEvent } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Button } from '@bice_vida/ds-components-button';
import { MotivationEnum, LeadProfileDataT, leadProfileDataAtom } from '@store/leadProfileData';
import { useAtom } from 'jotai';
import Image from 'next/image';
import SelectableCard from '@components/SelectableCard';
import styles from './ChooseMotivations.module.scss';

type MotivationT = {
  motivation:
  | MotivationEnum.MIDDLE_TERM_PROJECT
  | MotivationEnum.EDUCATION
  | MotivationEnum.RETIREMENT_PENSION
  | MotivationEnum.BACKUP_SAVINGS
  | MotivationEnum.NOT_DECIDED;
  imageSrc: string;
  text: string;
};

const ChooseMotivations = (): JSX.Element => {
  const router = useRouter();
  const { t: motivationsTrans } = useTranslation('motivations');
  const [leadProfileData, setLeadProfileData] = useAtom(leadProfileDataAtom);

  const onChangeMotivation = (
    event: React.ChangeEvent<HTMLInputElement>,
    optionId: LeadProfileDataT['motivation']
  ): void => {
    if (event.target.checked) {
      const leadData: LeadProfileDataT = {
        objectiveTimelineMonths: null,
        hasTransfer: null,
        underageChildren: null,
        savingsGoalAmount: null,
        motivation: optionId,
        hasChildren: null, //leadProfileData.hasChildren,
        netSalary: null, //leadProfileData.netSalary,
        gender: null, //leadProfileData.gender,
        age: null, //leadProfileData.age,
        savings: null, //leadProfileData.savings,
        childrenAge: null,
      };
      setLeadProfileData(leadData);
    }
  };

  const handleStartClick = async (): Promise<boolean> => router.push('/cuestionario');

  const motivations: MotivationT[] = [
    {
      motivation: MotivationEnum.MIDDLE_TERM_PROJECT,
      imageSrc: '/images/iconsWithBackground/heart_icon.png',
      text: motivationsTrans(`CARDS.${MotivationEnum.MIDDLE_TERM_PROJECT}`),
    },
    {
      motivation: MotivationEnum.EDUCATION,
      imageSrc: '/images/iconsWithBackground/hat.svg',
      text: motivationsTrans(`CARDS.${MotivationEnum.EDUCATION}`),
    },
    {
      motivation: MotivationEnum.RETIREMENT_PENSION,
      imageSrc: '/images/iconsWithBackground/coin.png',
      text: motivationsTrans(`CARDS.${MotivationEnum.RETIREMENT_PENSION}`),
    },
    {
      motivation: MotivationEnum.BACKUP_SAVINGS,
      imageSrc: '/images/iconsWithBackground/cup.svg',
      text: motivationsTrans(`CARDS.${MotivationEnum.BACKUP_SAVINGS}`),
    },
  ];

  return (
    <section>
      <div
        className={`${styles.motivation_container} container bg-lightgrey-10 pt-16 rounded-xl xl:mt-[-5.6rem] mb-28`}
      >
        <h3 className="pb-4 text-lg md:text-[2rem] md:leading-10 font-extrabold text-center">
          <Trans
            i18nKey={motivationsTrans('TITLE')}
            components={{
              semibold: <span className="font-semibold" />,
            }}
          />
        </h3>
        <h4 className="pb-11 text-sm md:text-base font-normal text-center">
          <Trans
            i18nKey={motivationsTrans('SUBTITLE')}
            components={{
              semibold: <span className="font-semibold" />,
            }}
          />
        </h4>
        <div className="flex flex-col justify-center items-center md:flex-row md:justify-center mb-10 md:mb-16 gap-4">
          {motivations.map(
            (motivation: MotivationT): JSX.Element => (
              <SelectableCard
                key={motivation.motivation}
                text={motivation.text}
                cardType="full"
                selected={motivation.motivation === leadProfileData.motivation}
                handleChange={(e: ChangeEvent<HTMLInputElement>): void =>
                  onChangeMotivation(e, motivation.motivation)
                }
                radioGroupName="gender"
              >
                <Image
                  src={motivation.imageSrc}
                  alt={`${motivation.text} Icon`}
                  width={64}
                  height={64}
                  quality={90}
                  className="hidden sm:block"
                />
                <Image
                  src={motivation.imageSrc}
                  alt={`${motivation.text} Icon`}
                  width={48}
                  height={48}
                  quality={90}
                  className="sm:hidden"
                />
              </SelectableCard>
            )
          )}
        </div>
        <div className="flex justify-center pb-12">
          <Button
            colorScheme="primary"
            rightIcon={<i className="material-icons">arrow_forward</i>}
            variant="primary"
            onClick={handleStartClick}
          >
            {motivationsTrans('BUTTON')}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ChooseMotivations;
