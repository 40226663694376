import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styles from './StickyHeader.module.scss';
import classnames from 'classnames';

type HeaderT = {
  hide?: boolean;
  className?: string;
  logoClassName?: string;
  logoRedirect?: boolean;
  offSetToScrollDown?: number;
  children: (args: { isScrollDown: boolean; isScrollingUp: boolean }) => JSX.Element;
};

const StickyHeader = ({
  children,
  hide = false,
  className,
  logoClassName,
  logoRedirect = false,
  offSetToScrollDown = 100,
}: HeaderT): JSX.Element => {
  const router = useRouter();
  const ref = useRef<HTMLElement>(null);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [, setLastScrollPosition] = useState(0);
  const [isScrollDown, setIsScrollDown] = useState(false);

  const classHeader = classnames(styles.header, styles.header_sticky, className, {
    [styles.header_hide]: !!hide,
    [styles.header_scrolled]: isScrollDown,
  });

  const classLogo = classnames(styles.header_logo, {
    [styles.header_logo_redirect]: logoRedirect,
  });

  const imageClassNames = classnames(logoClassName);

  useEffect(() => {
    const onScroll = () => {
      setIsScrollDown(window.pageYOffset > offSetToScrollDown);

      setLastScrollPosition((state) => {
        setIsScrollingUp(state > window.pageYOffset);
        return window.pageYOffset;
      });
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onClickLogoHandler = () => {
    if (logoRedirect) {
      router.push('/');
    }
  };
  const businessOrigin = {
    promotion: false,
    isVisitForBusiness: false,
  };
  const headerWithShadow = businessOrigin.promotion ? businessOrigin.promotion : false;

  return (
    <header ref={ref} className={classHeader} style={{ boxShadow: headerWithShadow ? 'none' : '' }}>
      <div className={classLogo}>
        <Image
          className={imageClassNames}
          src="/images/svg/bicevida-logo.svg"
          alt="bicevida landing header"
          width={160}
          height={20}
          onClick={onClickLogoHandler}
        />
        {/* <div style={{ width: '1px', height: '32px', backgroundColor: '#DDE3ED' }} />
        <Image
          style={{ marginTop: '20px' }}
          alt=""
          src="/images/logos/bice_lifeplan.png"
          width={84}
          height={38}
        /> */}
      </div>
      {children({ isScrollDown, isScrollingUp })}
    </header>
  );
};

export default StickyHeader;
