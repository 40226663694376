import { atomWithSessionStorage } from '../../config/jotai';

export enum MotivationEnum {
  MIDDLE_TERM_PROJECT = 'time_based_project',
  EDUCATION = 'education',
  RETIREMENT_PENSION = 'retirement-pension',
  NOT_DECIDED = 'not-decided',
  BACKUP_SAVINGS = 'backup_savings',
}

export type LeadProfileDataT = {
  motivation:
    | MotivationEnum.MIDDLE_TERM_PROJECT
    | MotivationEnum.EDUCATION
    | MotivationEnum.RETIREMENT_PENSION
    | MotivationEnum.BACKUP_SAVINGS
    | MotivationEnum.NOT_DECIDED
    | null;
  objectiveTimelineMonths: number | null;
  netSalary: number | null;
  hasChildren: boolean | null;
  hasTransfer: boolean | null;
  gender: null;
  age: number | null;
  underageChildren: number | null;
  savingsGoalAmount: number | null;
  savings: number | null;
  childrenAge: number | null;
};

export const leadProfileDataInitialState = {
  motivation: null,
  objectiveTimelineMonths: null,
  netSalary: null,
  hasChildren: null,
  hasTransfer: null,
  gender: null,
  age: null,
  underageChildren: null,
  savingsGoalAmount: null,
  savings: null,
  childrenAge: null,
};

export const leadProfileDataAtom = atomWithSessionStorage<LeadProfileDataT>(
  'lead-profile-data-state',
  leadProfileDataInitialState
);
