import { useDevice } from '@BICE_Vida/tsp-components/dist/hooks';

const easeInOutCubic = (time: number) =>
  time < 0.5 ? 4 * time * time * time : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1;

const useNavBarScroll = (
  duration = 1800,
  onFinishCallback?: () => void
): ((value: number, id: string) => void) => {
  const { mediaLarge } = useDevice();
  const headerOffset = mediaLarge ? 80 : 64;

  const executeScroll = (initialPosition: number, id: string) => {
    const destination = (document.getElementById(id)?.offsetTop || 0) - headerOffset;
    const difference = destination - initialPosition;
    if (difference < 1 && difference >= 0) return;
    let startTime = 0;

    const scrollTick = (timestamp: number) => {
      const progress = (timestamp - startTime) / duration;
      const scrollAmount = easeInOutCubic(progress);
      const nextScroll = initialPosition + scrollAmount * difference;
      if (progress < 0.99) {
        window.scrollTo({ left: 0, top: nextScroll });
        requestAnimationFrame(scrollTick);
      } else if (onFinishCallback) onFinishCallback();
    };

    requestAnimationFrame((timestamp) => {
      startTime = timestamp;
      scrollTick(timestamp);
    });
  };

  return executeScroll;
};

export default useNavBarScroll;
