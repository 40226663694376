declare global {
  interface Window {
    dataLayer: any[];
  }
}

type BaseDataLayerT = { [K: string]: unknown };

enum Enviroment {
  prod = 'pro',
  dev = 'dev',
  staging = 'stg',
  local = 'local',
}

export const dataLayerEventParamsTemplate = {
  page: '',
  page_title: '',
  product: '',
  action_type: '',
  action: '',
  view_type: 'web',
  product_type: '',
  ahorro: '',
  sexo: '',
  edad: '',
  otro: '',
  proteger_carga: '',
  valor: '',
};

const baseDataLayer = ({ event_params }: BaseDataLayerT): void => {
  const shouldRun = process.env.STAGE === Enviroment.prod;
  if (shouldRun) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'vpv',
      event_params,
    });
  }
};

export default baseDataLayer;
