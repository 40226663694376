/* eslint-disable @typescript-eslint/no-empty-function */
import { Button } from '@BICE_Vida/components';
import { ReactNode, useEffect } from 'react';
import { Footer } from '@BICE_Vida/tsp-components';
import { useDevice } from '@BICE_Vida/tsp-components/dist/hooks';
import StickyHeader from '@components/StickyHeader';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import styles from './LandingLayout.module.scss';
import useNavBarScroll from './useNavBarScroll';

type LandingLayout = {
  children: ReactNode;
  landingType?: 'landing/header/header' | 'what_is_apv/header/header';
  isNewLanding?: boolean;
};

const LandingLayout = ({
  children,
  landingType = 'landing/header/header',
  isNewLanding = false,
}: LandingLayout): JSX.Element => {
  const showBanner = process.env.SHOW_BANNER;
  const { t } = useTranslation(landingType);
  const { mediaLarge } = useDevice();
  const scrollable = useNavBarScroll(mediaLarge ? 1300 : 1800);

  const onClickHandler = (event: any) => {
    event.preventDefault();
    scrollable(window.scrollY, isNewLanding ? 'landing-plans' : 'landing-simulation');
  };

  return (
    <div>
      {!showBanner && (
        <StickyHeader offSetToScrollDown={mediaLarge ? 700 : 1000}>
          {({ isScrollDown, isScrollingUp }) => {
            const showActionButton = false; //hotfix (mediaLarge && isScrollDown) || (!mediaLarge && isScrollDown && !isScrollingUp);
            return (
              <>
                {/* <NavBar
                  titleNavbarOpen={t('landing_header.title')}
                  titleNavbarClose={t('landing_header.close')}
                  showMenu={!showActionButton}
                  landingType={landingType}
                /> */}
                <div
                  className={classNames(styles.button_container, {
                    [styles.visible]: showActionButton,
                  })}
                >
                  <div className="hidden" /*className="md:hidden" original*/ >
                    <Button
                      id="landing-header-button"
                      data-testid="landing-header-button-action"
                      text={t('landing_header.button_mobile')}
                      onClick={onClickHandler}
                      size="medium"
                      icon="arrow_forward"
                      iconPosition="right"
                    />
                  </div>
                  <div className="hidden" /*className="hidden md:block" orginal*/>
                    <Button
                      id="landing-header-button"
                      data-testid="landing-header-button-action"
                      text={t('landing_header.button_desktop')}
                      onClick={onClickHandler}
                      size="medium"
                      icon="arrow_forward"
                      iconPosition="right"
                    />
                  </div>
                </div>
              </>
            );
          }}
        </StickyHeader>
      )}
      <div>{children}</div>

      <Footer footerClassNames="test" networkContainerClassName="flex" />
    </div>
  );
};

export default LandingLayout;
