import { RadioButton } from '@bice_vida/ds-components-radio-button';
import { cva } from 'class-variance-authority';
import { PropsWithChildren, useRef } from 'react';
import styles from './SelectableCard.module.scss';
import { Trans } from 'next-i18next';

type SelectableCardProps = React.HTMLProps<HTMLDivElement> & {
  cardType?: 'full' | 'input';
  selected?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string;
  classNames?: string;
  radioGroupName: string;
};

const fullCardStyle = cva(styles.full_card, {
  variants: {
    focus: {
      true: styles.focus,
    },
  },
});

const inputCardStyle = cva(styles.input_card, {
  variants: {
    focus: {
      true: styles.focus,
    },
  },
});

const SelectableCard = ({
  radioGroupName,
  cardType,
  selected,
  handleChange,
  classNames,
  text,
  className,
  children,
}: PropsWithChildren<SelectableCardProps>): JSX.Element => {
  const fullRadioButtonRef = useRef<HTMLInputElement>(null);
  const inputRadioButtonRef = useRef<HTMLInputElement>(null);

  const [inputTextClassName, fullTextClassName] = className
    ? className.split('|').map((s) => s.trim())
    : ['', ''];

  const handleFullDivClick = () => {
    if (fullRadioButtonRef.current) {
      fullRadioButtonRef.current.click();
    }
  };

  const handleInputDivClick = () => {
    if (inputRadioButtonRef.current) {
      inputRadioButtonRef.current.click();
    }
  };

  if (cardType === 'input') {
    return (
      <div
        className={inputCardStyle({ focus: selected, className: classNames })}
        onClick={handleInputDivClick}
      >
        <div className={styles.radio_button}>
          <RadioButton
            ref={inputRadioButtonRef}
            name={radioGroupName}
            checked={selected}
            onChange={handleChange}
          />
        </div>
        <div className={styles.content}>
          {children}
          <span className={inputTextClassName}>
            <Trans
              i18nKey={text}
              components={{ br: <br /> }}
            />
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={fullCardStyle({ focus: selected, className: classNames })}
      onClick={handleFullDivClick}
    >
      <div className={styles.radio_button}>
        <RadioButton
          ref={fullRadioButtonRef}
          name={radioGroupName}
          checked={selected}
          onChange={handleChange}
        />
      </div>
      <div className={styles.content}>
        {children}
        <span className={fullTextClassName}>
          <Trans
            i18nKey={text}
            components={{ br: <br /> }}
          />
        </span>
      </div>
    </div>
  );
};

SelectableCard.displayName = 'SelectableCard';
export default SelectableCard;
