import Image from 'next/image';
import { useTranslation, Trans } from 'next-i18next';
import styles from './LandingHero.module.scss';

const LandingHero = (): JSX.Element => {
  const { t: heroTrans } = useTranslation('hero');

  return (
    <section className="container flex flex-col-reverse lg:flex-row lg:pr-12 lg:mt-20">
      <div className="lg:w-[55%] flex flex-col justify-center">
        <div className="mb-3 lg:mb-6">
          <p
            className={`${styles.badge_finetune} flex items-center w-fit bg-lightgrey-10 text-xs px-4 rounded-xl text-darkgrey-80`}
          >
            {heroTrans('BADGE')}
            <Image
              alt={heroTrans('IMAGE_ALT_DESKTOP')}
              src="/images/iconsWithBackground/mini-pig.png"
              width={18}
              height={16}
              quality={90}
              priority={true}
              className="ml-1"
            />
          </p>
        </div>
        <h1 className="font-bold text-lg lg:text-2xl lg:mb-2">
          <Trans
            i18nKey={heroTrans('TITLE')}
            components={{
              primary: <span className="text-primary" />,
            }}
          />
        </h1>
        <h1 className="font-extrabold text-2xl lg:text-[2.5rem] mb-4 lg:mb-4 md:leading-48px">
          <Trans i18nKey={heroTrans('SUBTITLE')} />
        </h1>
        <h2 className="text-sm lg:text-base mb-8 md:mb-14 text-darkgrey-80 relative font-normal">
          <Trans
            i18nKey={heroTrans('DESCRIPTION')}
            components={{
              semibold: <span className="font-bold" />,
            }}
          />
        </h2>
      </div>
      <div className="block w-full lg:w-1/2 lg:pl-24 mb-4 mt-20	lg:mt-0 lg:mb-0">
        <Image
          alt={heroTrans('IMAGE_ALT_DESKTOP')}
          src="/images/hero/asesor-hero-img.png"
          width={442}
          height={496}
          style={{ objectPosition: '50% 20%' }}
          className="rounded-xl object-cover h-[10.5rem] lg:h-full"
        />
      </div>
    </section>
  );
};

export default LandingHero;
