import { isValid, parseISO } from 'date-fns';
import deepmerge from 'deepmerge';
import { atom } from 'jotai';

const getInitialValue = <T>(key: string, initialValue: T): T => {
  try {
    const value = sessionStorage.getItem(key);
    if (value === null) {
      return initialValue;
    }
    const parsed: T = JSON.parse(value);
    if (typeof parsed === 'object' && parsed !== null && !Array.isArray(parsed)) {
      return deepmerge(initialValue, parsed);
    }
    return parsed;
  } catch (error) {
    return initialValue;
  }
};

export const atomWithSessionStorage = <T>(key: string, initialValue: T) => {
  const baseAtom = atom<T>(getInitialValue(key, initialValue));
  baseAtom.debugLabel = key;
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);

      if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, JSON.stringify(nextValue));
      }
    }
  );

  return derivedAtom;
};

const findAndParseDate = (object: any) => {
  if (typeof object !== 'object') return object;
  const parsed: Record<string | number, any> = {};
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === 'string' && isValid(parseISO(object[key]))) {
      parsed[key] = parseISO(object[key]);
    } else if (typeof object[key] === 'object') {
      parsed[key] = findAndParseDate(object[key]);
    } else {
      parsed[key] = object[key];
    }
  });
  return parsed;
};

export const atomWithSessionStorageDate = <T>(key: string, initialValue: T) => {
  const baseAtom = atom<T>(findAndParseDate(getInitialValue(key, initialValue)));

  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom as any, nextValue);

      if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, JSON.stringify(nextValue));
      }
    }
  );

  return derivedAtom;
};
