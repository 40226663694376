import ChooseMotivations from '@templates/ChooseMotivations/ChooseMotivations';
import { useSetAtom } from 'jotai';
import { InferGetStaticPropsType, NextPage } from 'next';
import { useEffect } from 'react';
import { customServerSideTranslation } from '../../config/i18nextConfig';
import getUFService from '../services/getUf';
import { ufAtom } from '../store/constant/uf';
import LandingHero from '../templates/LandingHero/LandingHero';
import LandingLayout from '@layouts/LandingLayout';
import { trackViewLandingPage } from 'dataLayer/asesorEvents';

export const getStaticProps = async ({ locale }: { locale: string }) => {
  const ufServiceResponse = await getUFService();
  const uf = ufServiceResponse?.data?.value;

  return {
    revalidate: process.env.STAGE === 'pro' ? 300 : 60,
    props: {
      uf: uf,
      ...(await customServerSideTranslation(locale, [
        'hero',
        'common',
        'motivations',
        'forms/general_form',
      ])),
    },
  };
};

const DigitalAdvisorIndex: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  uf,
}: {
  uf: number;
}): JSX.Element => {
  const setUf = useSetAtom(ufAtom);

  useEffect((): void => {
    trackViewLandingPage();
  }, []);

  useEffect((): void => {
    setUf(uf);
  }, [uf]);

  return (
    <LandingLayout>
      <LandingHero />
      <ChooseMotivations />
    </LandingLayout>
  );
};

DigitalAdvisorIndex.displayName = 'DigitalAdvisorIndex';

export default DigitalAdvisorIndex;
