import { MotivationEnum } from '@store/leadProfileData';

export const motivations = [
  {
    motivation: MotivationEnum.MIDDLE_TERM_PROJECT,
    product: 'Lograr un proyecto a mediano plazo',
  },
  {
    motivation: MotivationEnum.EDUCATION,
    product: 'Asegurar la educación de mis hijos',
  },
  {
    motivation: MotivationEnum.RETIREMENT_PENSION,
    product: 'Mejorar mi pensión',
  },
  {
    motivation: MotivationEnum.BACKUP_SAVINGS,
    product: 'Tener ahorro como respaldo',
  },
];

export type QuestionaryT = {
  motivation:
    | MotivationEnum.MIDDLE_TERM_PROJECT
    | MotivationEnum.EDUCATION
    | MotivationEnum.RETIREMENT_PENSION
    | MotivationEnum.BACKUP_SAVINGS
    | MotivationEnum.NOT_DECIDED
    | null;
  objectiveTimelineMonths: number | null;
  netSalary: number | null;
  hasChildren: boolean | null;
  hasTransfer: boolean | null;
  gender: string | null;
  age: number | null;
  underageChildren: number | null;
  savingsGoalAmount: number | null;
  savings: number | null;
  childrenAge: number | null;
};
